<template>
  <!--banner广告管理-->
  <div class="banner">
    <a-spin :spinning="spinning">
      <div class="container">
        <div class="right">
          <div class="right_select">
            状态:  &nbsp;<a-radio-group default-value="all" button-style="solid" @change="statusButton($event)">
            <a-radio-button value="null">
              全部
            </a-radio-button>
            <a-radio-button value="up">
              已上架
            </a-radio-button>
            <a-radio-button value="down">
              已下架
            </a-radio-button>
          </a-radio-group>
          </div>
          <div class="right_list">
            <a-button type="primary" style="margin-left: 1%;margin-bottom: 3%" @click="addOpen">新增</a-button>
            <a-table :columns="tableColumns" :data-source="tableData" rowKey="id" :pagination="false">
                <!--          图片-->
                <span slot="images" slot-scope="text,item">
                  <img :src="item.displayUrl" alt="" style="height: 50px;width: 100px">
                </span>
              <!--          跳转连接-->
              <span slot="link" slot-scope="text,item" v-if="item.targetUrl">
                <a :href="item.targetUrl">点击打开</a>
              </span>
<!--              排序-->
              <template slot="sort" slot-scope="text,item,index" v-if="item.isShow === 1">
                <span v-if="index === 0">
                  <a @click="homeBannerTableUpDown(item, 'down')">下移</a>
                </span>
                <span v-else-if="index === tableData.length - 1">
                  <a @click="homeBannerTableUpDown(item, 'up')">上移</a>
                </span>
                <span v-else>
                  <a @click="homeBannerTableUpDown(item, 'up')">上移</a>
                  <a style="margin-left: 13px" @click="homeBannerTableUpDown(item, 'down')">下移</a>
                </span>
              </template>
<!--              操作-->
              <span slot-scope="text,item" slot="operation">
                <a @click="releaseOpen(item)">发布</a>
                <a @click="editOpen(item)" style="margin-left: 10px">修改</a>
                <a v-if="item.isShow === 1" style="margin-left: 10px" @click="homeBannerTableUpDownShelf(item.id)">下架</a>
                  <a v-else style="margin-left: 10px" @click="homeBannerTableUpDownShelf(item.id)">上架</a>
              </span>
            </a-table>

<!------------------------------- 对话框-------------------------------------->
<!--            新增-->
            <a-drawer
                title="新增"
                :width="480"
                :visible="addVisible"
                @close="addClose"
            >
            <a-form-model :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addFrom" :rules="addFormRules">
              <a-form-model-item label="列表封面图" prop="displayUrl">
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -10px">
                  <div class="uploadImg">
                    <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('addIcon')" ref="addIcon"/>
                  </div>
                  <div class="upload_image">
                    <img
                        alt=""
                        v-if="addForm.displayUrl"
                        style="width: 145px;height:55px;"
                        :src="addForm.displayUrl"
                    />
                  </div>
                </div>
                <span style="font-size: 14px">注：尺寸 1920*520，大小不超过1M</span>
              </a-form-model-item>
              <a-form-model-item label="跳转链接" prop="targetUrl">
                <a-textarea placeholder="请输入" v-model="addForm.targetUrl" style="width: 100%;height: 80px"/>
              </a-form-model-item>
              <div
              :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
              }"
              >
                <a-button @click="addClose">
                  取消
                </a-button>
                <a-button style="margin-left: 15px" type="primary" @click="addSubmit">
                  确定
                </a-button>
              </div>
            </a-form-model>
            </a-drawer>

<!--            修改-->
            <a-drawer
                title="修改"
                :width="480"
                :visible="editVisible"
                @close="editClose"
            >
              <a-form-model ref="editForm" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="addFormRules">
                <a-form-model-item label="列表封面图" prop="targetUrl">
                  <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -10px">
                    <div class="uploadImg">
                      <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('editIcon')" ref="editIcon"/>
                    </div>
                    <div class="upload_image">
                      <img
                          alt=""
                          v-if="editForm.displayUrl"
                          style="width: 145px;height:55px;"
                          :src="editForm.displayUrl"
                      />
                    </div>
                  </div>
                  <span style="font-size: 14px">注：尺寸 1920*520，大小不超过1M</span>
                </a-form-model-item>
                <a-form-model-item label="跳转链接" prop="displayUrl">
                  <a-textarea placeholder="请输入" v-model="editForm.targetUrl" style="width: 100%;height: 80px"/>
                </a-form-model-item>
                <div
                :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
                }"
                >
                  <a-button @click="editClose">
                    取消
                  </a-button>
                  <a-button style="margin-left: 15px" type="primary" @click="editSubmit">
                    确定
                  </a-button>
                </div>
              </a-form-model>
            </a-drawer>
          </div>
        </div>
<!--------------------发布-------------------->
        <a-drawer
            title="发布"
            :width="480"
            :visible="releaseVisible"
            @close="releaseClose"
        >
          <a-spin :spinning="releaseSpinning">
            <a-table :bordered="true" rowKey="name" :pagination="false" :columns="releaseAppColumns" :data-source="releaseAppData">
              <template slot-scope="text,item,index" slot="communityAppStatus">
                <a-switch v-model="item.dataStatus" :checkedChildren="'是'" :unCheckedChildren="'否'" @change="releaseStatus($event, item, )" default-checked/>
              </template>
            </a-table>
            <a-table :bordered="true" rowKey="name" style="margin-top: 40px;margin-bottom: 40px" :pagination="false" :columns="releaseCommunityColumns" :data-source="releaseCommunityData">
              <template slot-scope="text,item,index" slot="communityColumnsStatus">
                <a-switch v-model="item.status" :checkedChildren="'是'" :unCheckedChildren="'否'" @change="releaseStatus($event, item)" default-checked/>
              </template>
            </a-table>
            <a-table :bordered="true" rowKey="name" :pagination="false" :columns="releaseOtherColumns" :data-source="releaseOtherData">
              <template slot-scope="text,item,index" slot="communityOtherStatus">
                <a-switch v-model="item.status" :checkedChildren="'是'" :unCheckedChildren="'否'" @change="releaseStatus($event, item)" default-checked/>
              </template>
            </a-table>
          </a-spin>
        </a-drawer>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  DelHomeBannerCloseExtension,
  getHomeBanner, getHomeBannerExtension, getHomeBannerIndexExtension,
  postHomeBannerAdd, postHomeBannerOpenExtension,
  putHomeBannerEdit,
  putHomeBannerUpDown,
  putHomeBannerUpDownShelf
} from "@/service/systemApi";
import {update} from "@/utils/update";
export default {
  name: "homeBannerManagement",
  data(){
    return{
      releaseSpinning: false,
      spinning:false,
      tableColumns: [
        {
          title: '图片',
          dataIndex: 'src',
          scopedSlots: {customRender: 'images'}
        },
        {
          title: '跳转连接',
          scopedSlots: {customRender: 'link'}
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime'
        },
        {
          title: '排序',
          width: '15%',
          align: 'left',
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: '操作',
          width: '15%',
          align: 'center',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      releaseAppColumns: [
        {
          title: 'APP首页',
          width: '50%',
          dataIndex: 'name'
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'communityAppStatus'}
        }
      ],
      releaseAppData: [],
      releaseCommunityColumns: [
        {
          title: '社区',
          width: '50%',
          dataIndex: 'name'
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'communityColumnsStatus'}
        }
      ],
      releaseCommunityData: [
        {
          id: '1',
          name: '首页',
          code: 'sq_index',
          type: 'bannerCommunity',
          status: true
        },
        {
          id: '2',
          code: 'sq_html',
          type: 'bannerCommunity',
          name: '社区内容分享页',
          status: true
        }
      ],
      BannerId: '',
      releaseOtherColumns: [
        {
          title: '其他',
          width: '50%',
          dataIndex: 'name'
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'communityOtherStatus'}
        }
      ],
      releaseOtherData: [
        {
          id: '1',
          name: '个人中心首页',
          code: 'my_index',
          type: 'bannerMy',
          status: false
        }
      ],
      tableData: [],
      releaseVisible: false,
      addVisible: false,
      editVisible: false,
      addForm: {
        displayUrl: '',
      },
      editForm: {
        displayUrl: '',
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      addFormRules: {
        displayUrl: [{ required: true, message: '请上传图标', trigger: 'blur' }],
        targetUrl: [{ required: true, message: '请输入跳转链接', trigger: 'blur' }],
      }
    }
  },
  mounted() {
    this.getHomeBannerTableData()
    this.getAppIndexBanner()
  },
  methods:{
    async uploadPic(type){ // 图片上传
      let file = this.$refs[type].files[0];
      if ( file !== undefined ) {
        if (file.size < 1024000){ // 限制大小
          const image = await update(file)
          if(image.code === 0){
            if ( type === "addIcon" ) {
              this.addForm.displayUrl = image.data.url
            }else {
              this.editForm.displayUrl = image.data.url
            }
            this.$message.success("图片上传成功！")
          }else {
            this.$message.error(image.message)
          }
        }else {
          this.$message.error("请上传小于500kb的图片")
        }
      }
    },
    async getAppIndexBanner () { // 获取发布抽屉中APP首页栏目中的栏目数据
      const response = await getHomeBannerIndexExtension('AD')
      let data = []
      if (response.code === 0) {
        response.data.forEach(item => {
          if (item.status === 'OPEN') { // 把接口返回的数据中状态为 OPEN 的值提取出来
            data.push(item) // 提取拼接指定状态的数据
          }
        })
        this.releaseAppData = data
      }else {
        this.$message.warning(response.message)
      }
    },
    addClose () { // 新增关闭
      this.addVisible = false
    },
    addOpen () { // 新增打开
      this.addVisible = true
    },
    async addSubmit (data) { // 新增提交保存
      this.$refs.addFrom.validate( async valid => {
       if (valid) {
         this.spinning = true
         data = {
           "displayUrl": this.addForm.displayUrl,
           "targetUrl": this.addForm.targetUrl
         }
         const response = await postHomeBannerAdd(data)
         if (response.code === 0) {
           this.$message.success('新增成功')
           await this.getHomeBannerTableData()
           this.addVisible = false
           this.addForm = {
             displayUrl: ''
           }
           this.$refs.addIcon.value = null
         }else {
           this.$message.warning(response.message)
         }
         this.spinning = false
       }else {
         this.$message.error("请填写全部表单")
       }
      })
    },
    editClose () { // 修改关闭
      this.editVisible = false
    },
    async editOpen (row) { // 修改打开
      this.spinning = true
      const response = await getHomeBanner('null', row.id)
      if (response.code === 0) {
        this.editForm = response.data[0]
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
      this.editVisible = true
    },
    async editSubmit () { // 修改提交
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          this.spinning = true
          let data = {
            "id": this.editForm.id,
            "displayUrl": this.editForm.displayUrl,
            "targetUrl": this.editForm.targetUrl
          }
          const response = await putHomeBannerEdit(data)
          if (response.code === 0) {
            this.$message.success("修改成功")
            await this.getHomeBannerTableData()
            this.editVisible = false
            this.$refs.editIcon.value = null
          }else {
            this.$message.warning(response.message)
          }
          this.spinning = false
        }else {
          this.$message.warning("请填写全部表单项")
        }
      })
    },
    async getHomeBannerTableData () { // 获取 table 列表
      this.spinning = true
      const response = await getHomeBanner(this.status,'')
      if (response.code === 0) {
        this.tableData = response.data
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async releaseOpen (row) { // 发布抽屉打开
      this.spinning = true
      const response = await getHomeBannerExtension(row.id) // 调用查询栏目状态接口
      if (response.code === 0) {
        this.BannerId = row.id // 获取要修改的 bannerId 保存到外部
        this.releaseCommunityData[0].status = response.data.some(v => {
          return v.code === 'sq_index'
        }) // 判断三个固定栏目的值 是否在接口返回的数据中，存在 即状态开启 不存在则 状态关闭
        this.releaseCommunityData[1].status = response.data.some(v => {
          return v.code === 'sq_html'
        })
        this.releaseOtherData[0].status = response.data.some(v => {
          return v.code === 'my_index'
        })
        this.releaseAppData.forEach((a,index) => { // APP首页的栏目值的状态
          let status = response.data.some(b => {
            return a.code === b.code
          })
          a.dataStatus = status // 由于数组没有get、set方法，所以在用循环操作数组的情况下，即便使用了双向绑定，也会存在数据无法更新的问题
          this.$set(this.releaseAppData, index, a) // 使用 $set 强制 vue 再次渲染数据
        })
        this.releaseVisible = true
      }
      this.spinning = false
    },
    releaseClose () { // 发布抽屉关闭
      this.releaseVisible = false
    },
    async releaseStatus (status,row) { // 发布状态点击方法，开启关闭发布
      this.releaseSpinning = true
      let type = row.type // 判断当前操作的是哪个栏目的状态
      if (type === undefined) type = 'bannerApp' // APP首页中的栏目不带有 type 属性 在此进行区分
      if (status) { // （开启）判断当前是开启操作还是关闭操作
        let data = [ // 定义传参的格式、类型和值
          {
            "bannerId": this.BannerId,
            "advCode": row.code
          }
        ]
        const response = await postHomeBannerOpenExtension(data) // 调用开启接口
        if (response.code === 0) { // 判断状态
          this.$message.success("发布成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else { // （关闭）判断当前是开启操作还是关闭操作
        let data = { // 定义传参的格式、类型和值
          "code": row.code,
          "type": type
        }
        const response = await DelHomeBannerCloseExtension(this.BannerId, data) // 调用关闭接口
        if (response.code === 0) {
          this.$message.success('关闭成功！')
        }else {
          this.$message.warning(response.message)
        }
      }
      // 更新双向绑定的数据源
      this.releaseAppData.forEach((a,index) => {
        if (a.code === row.code) {
          this.$set(this.releaseAppData, index, a)
          return
        }
      })
      this.releaseSpinning = false
    },
    async homeBannerTableUpDown (row,move) { // 上移下移banner广告
      this.spinning = true
      const response = await putHomeBannerUpDown(row.id, move)
        if (response.code === 0) {
          await this.getHomeBannerTableData()
        }else {
          this.$message.warning(response.message)
        }
      this.spinning = false
    },
    async homeBannerTableUpDownShelf (id) { // 上架下架banner广告
      this.spinning = true
      const response = await putHomeBannerUpDownShelf(id)
      if (response.code === 0) {
        this.$message.success('操作成功')
        await this.getHomeBannerTableData()
      }else {
        this.$message.warning(response.massage)
      }
      this.spinning = false
    },
    async statusButton (e) {
      this.spinning = true
      this.status = e.target.value
      const response = await getHomeBanner(this.status, '')
      if (response.code === 0) {
        this.tableData = response.data
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    }
  }

}
</script>

<style lang="scss" scoped>

.banner{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .container {
    display:flex;
    justify-content: space-between;
    width: 100%;
  }
  .left {
    width: 14%;
    background: white;
    ::v-deep.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
      border-right:none;
    }
  }
  .right {
    flex:1;
    margin-left: 1%;
    background: white;
    padding: 15px;
    .right_list{
      margin-top: 2%;
      .Info_list{
        margin:  auto;
        width: 100%;
        height: 100%;
        min-height:549px;
        .Info_list_Template{
          border-radius: 3px;
          border:#DDDDDD  1px solid;
          background-color: white;
          width: 24%;
          float: left;
          height: auto;
          margin: 1%;
          .Template{
            .Template_title{
              font-size: 15px;
              font-weight: bolder;
              display: flex;
              justify-content: space-between;
              margin: 5px;
            }
          }
          .Template_btn{
            cursor: pointer;
            height: 31px;
            display: flex;
            margin: 0 auto;
            align-items: center;
            padding: 0 2%;
            background-color:rgba(247, 249, 250, 1);
            justify-content: space-between;
            .operation_btn{
              width: 40%;
              display: flex;
              justify-content: space-between;
            }

          }
        }
      }
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 150px;
  height: 60px;
}
</style>
